import React from "react";

export default function Home() {
  return (
      <div className="container">
          <div align="center">
            <h1>Happy 50th Anniversary</h1>
          </div>
          <div className="col-lg-8" align="center">
              <img
                className="img-fluid rounded mb-4 mb-lg-0"
                src="https://www.vamanaraogaru.com/images/DSC_0020.jpg"
                style={{ flex: 1, height: 600, width: 800 }}
                alt=""
              />
          </div>
      </div>
  );
}
