import React, { Component }  from "react";
import axios from '../../api/axios';

export default class Form extends Component {
	constructor(props) {
		super(props);
		this.state = {
		  name: '',
		  wishes: '',
		};
		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleChange = this.handleChange.bind(this);
	      }
	
	handleChange(event) {
		let inputValue = event.target.value;
		const stateField = event.target.name;
		this.setState({
			[stateField]: inputValue,
		});
	}

	async handleSubmit(event) {
		event.preventDefault();
		console.log(this.state);
		await axios.post(
			'https://fg21gzr12j.execute-api.us-east-1.amazonaws.com/dev/comments?db=new',null,
		  	{params: this.state}
		).then(response => {
			if (response['status'] === 200){
				window.location.replace("/nanna/#thoughts")
			}
		      }).catch(error => {
			  console.log("handlesubmit error for blog ", error)
		      });
	      }

	render() {
		return (
			<div align="center">
				<div>
					<form>
						<h4>Share your memories and wishes below</h4>
						<p><b>Name</b> &nbsp;&nbsp;: &nbsp;&nbsp;&nbsp;&nbsp;<input type="text" value={this.state.name} name="name" size="73" onChange={this.handleChange}/></p>
						<p><b>Wishes</b> : &nbsp;&nbsp; <textarea name="wishes"  rows="5" cols="72" onChange={this.handleChange}/></p>
						<p><button className="btn btn-danger" onClick={this.handleSubmit} >Submit</button></p>
					</form>
				</div>
				{/* <div>
					<ViewComments />
				</div> */}
			</div>
		);
	}
	    
}