import React, { useState, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import axios from '../../api/axios';
// import Moment from 'moment';

export default function ViewComments() {

    const [data, getData] = useState([])

    useEffect(() => {
        axios.get("/comments").then(function (res) {
            console.log(res.data);
            getData(res.data);
          })
          .catch(function (error) {
            // handle error
            console.log(error);
          });
    }, [])

    // console.log( data);

    return (
        <div className="jumbotron text-center form-inline guestView" align="center" >
            <div className="container">
                {data.map((item, i) => (
                    <div key={item.id} className="jumbotron text-center form-inline guestView">
                        <div className="container" id="timeline_container">	
                            <ul className="timeline">
                                <li ng-repeat="comment in comments | filter:isTypeActive(event)" ng-class-even="'timeline-inverted'" >
                                            <div className="timeline-badge" ng-class="comment.type">
                                                <i className="glyphicon glyphicon-comment" ></i>
                                    </div>
                                            <div className="timeline-panel">
                                                    <div className="timeline-heading">
                                                        <p className="timeline-title"><b>{item.Name}</b></p>
                                                    </div>
                                                    <div className="timeline-body">
                                                        <p>{item.Comments}</p>
                                                    </div>
                                            </div>
                                </li>
                                </ul>
                    
                        </div>
                    </div>
                
                ))}
            </div>
            <Outlet />
        </div>
    );
}