import React from "react";
import { NavLink } from "react-router-dom";

function Navigation() {
  return (
    <div className="navigation">
      <nav className="navbar navbar-expand-md navbar-dark bg-primary flex-sm-nowrap flex-wrap">
          <div className="container-fluid">
              <span className="navbar-brand flex-grow-2">
              <a href="/">
                <h1>Nadella VamanaRao Garu</h1>
              </a>
              </span>
              <div className="navbar-collapse collapse flex-grow-1 justify-content-center" id="navbar5">
                  <ul className="navbar-nav mx-auto">
                      {/* <li className="nav-item">
                          <NavLink className="nav-link" to="/lane">Journey</NavLink>
                      </li> */}
                      <li className="nav-item">
                          <NavLink className="nav-link" to="/album">Album</NavLink>
                      </li>
                      {/* <li className="nav-item">
                          <NavLink className="nav-link" to="/thoughts">Wishes</NavLink>
                      </li> */}
                      {/* <li className="nav-item">
                          <NavLink className="nav-link" to="/give">Post</NavLink>
                      </li> */}
                  </ul>
              </div>
          </div>
      </nav>
    </div>
  );
}

export default Navigation;
