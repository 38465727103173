import React, { Component } from 'react';
import "../../assets/css/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

export default class DemoCarousel extends Component {
    render() {
        return (
            <Carousel dynamicHeight={true} 
            useKeyboardArrows={true}
            transitionTime={500}
            showStatus={true}
            showThumbs={false} interval={20000} 
            swipeable={true} autoPlay>
                <div>
                    <img  alt="legend" src="https://www.vamanaraogaru.com/images/www/image001.jpg"/>
                    <p className="legend">Legend 1</p>
                </div>
                <div>
                    <img  alt="legend" src="https://www.vamanaraogaru.com/images/www/image002.jpg"/>
                    <p className="legend">Legend 1</p>
                </div>
                <div>
                    <img  alt="legend" src="https://www.vamanaraogaru.com/images/www/image003.jpg"/>
                    <p className="legend">Legend 1</p>
                </div>
                <div>
                    <img  alt="legend" src="https://www.vamanaraogaru.com/images/www/image004.jpg"/>
                    <p className="legend">Legend 1</p>
                </div>
                <div>
                    <img  alt="legend" src="https://www.vamanaraogaru.com/images/www/image005.jpg"/>
                    <p className="legend">Legend 1</p>
                </div>
                <div>
                    <img  alt="legend" src="https://www.vamanaraogaru.com/images/www/image006.jpg"/>
                    <p className="legend">Legend 1</p>
                </div>
                <div>
                    <img  alt="legend" src="https://www.vamanaraogaru.com/images/www/image007.jpg"/>
                    <p className="legend">Legend 1</p>
                </div>
                <div>
                    <img  alt="legend" src="https://www.vamanaraogaru.com/images/www/image008.jpg"/>
                    <p className="legend">Legend 1</p>
                </div>
                <div>
                    <img  alt="legend" src="https://www.vamanaraogaru.com/images/www/image009.jpg"/>
                    <p className="legend">Legend 1</p>
                </div>
                <div>
                    <img  alt="legend" src="https://www.vamanaraogaru.com/images/www/image010.jpg"/>
                    <p className="legend">Legend 1</p>
                </div>
                <div>
                    <img  alt="legend" src="https://www.vamanaraogaru.com/images/www/image011.jpg"/>
                    <p className="legend">Legend 1</p>
                </div>
                <div>
                    <img  alt="legend" src="https://www.vamanaraogaru.com/images/www/image012.jpg"/>
                    <p className="legend">Legend 1</p>
                </div>
                <div>
                    <img  alt="legend" src="https://www.vamanaraogaru.com/images/www/image013.jpg"/>
                    <p className="legend">Legend 1</p>
                </div>
                <div>
                    <img  alt="legend" src="https://www.vamanaraogaru.com/images/www/image014.jpg"/>
                    <p className="legend">Legend 1</p>
                </div>
                <div>
                    <img  alt="legend" src="https://www.vamanaraogaru.com/images/www/image015.jpg"/>
                    <p className="legend">Legend 1</p>
                </div>
                <div>
                    <img  alt="legend" src="https://www.vamanaraogaru.com/images/www/image016.jpg"/>
                    <p className="legend">Legend 1</p>
                </div>
                <div>
                    <img  alt="legend" src="https://www.vamanaraogaru.com/images/www/image017.jpg"/>
                    <p className="legend">Legend 1</p>
                </div>
                <div>
                    <img  alt="legend" src="https://www.vamanaraogaru.com/images/www/image018.jpg"/>
                    <p className="legend">Legend 1</p>
                </div>
                <div>
                    <img  alt="legend" src="https://www.vamanaraogaru.com/images/www/image019.jpg"/>
                    <p className="legend">Legend 1</p>
                </div>
                <div>
                    <img  alt="legend" src="https://www.vamanaraogaru.com/images/www/image020.jpg"/>
                    <p className="legend">Legend 1</p>
                </div>
                <div>
                    <img  alt="legend" src="https://www.vamanaraogaru.com/images/www/image021.jpg"/>
                    <p className="legend">Legend 1</p>
                </div>
                <div>
                    <img  alt="legend" src="https://www.vamanaraogaru.com/images/www/image022.jpg"/>
                    <p className="legend">Legend 1</p>
                </div>
                <div>
                    <img  alt="legend" src="https://www.vamanaraogaru.com/images/www/image023.jpg"/>
                    <p className="legend">Legend 1</p>
                </div>
                <div>
                    <img  alt="legend" src="https://www.vamanaraogaru.com/images/www/image024.jpg"/>
                    <p className="legend">Legend 1</p>
                </div>
                <div>
                    <img  alt="legend" src="https://www.vamanaraogaru.com/images/www/image025.jpg"/>
                    <p className="legend">Legend 1</p>
                </div>
                <div>
                    <img  alt="legend" src="https://www.vamanaraogaru.com/images/www/image026.jpg"/>
                    <p className="legend">Legend 1</p>
                </div>
                <div>
                    <img  alt="legend" src="https://www.vamanaraogaru.com/images/www/image027.jpg"/>
                    <p className="legend">Legend 1</p>
                </div>
                <div>
                    <img  alt="legend" src="https://www.vamanaraogaru.com/images/www/image028.jpg"/>
                    <p className="legend">Legend 1</p>
                </div>
                <div>
                    <img  alt="legend" src="https://www.vamanaraogaru.com/images/www/image029.jpg"/>
                    <p className="legend">Legend 1</p>
                </div>
                <div>
                    <img  alt="legend" src="https://www.vamanaraogaru.com/images/www/image030.jpg"/>
                    <p className="legend">Legend 1</p>
                </div>
                <div>
                    <img  alt="legend" src="https://www.vamanaraogaru.com/images/www/image031.jpg"/>
                    <p className="legend">Legend 1</p>
                </div>
                <div>
                    <img  alt="legend" src="https://www.vamanaraogaru.com/images/www/image032.jpg"/>
                    <p className="legend">Legend 1</p>
                </div>
                <div>
                    <img  alt="legend" src="https://www.vamanaraogaru.com/images/www/image033.jpg"/>
                    <p className="legend">Legend 1</p>
                </div>
                <div>
                    <img  alt="legend" src="https://www.vamanaraogaru.com/images/www/image034.jpg"/>
                    <p className="legend">Legend 1</p>
                </div>
                <div>
                    <img  alt="legend" src="https://www.vamanaraogaru.com/images/www/image035.jpg"/>
                    <p className="legend">Legend 1</p>
                </div>
                <div>
                    <img  alt="legend" src="https://www.vamanaraogaru.com/images/www/image036.jpg"/>
                    <p className="legend">Legend 1</p>
                </div>
                <div>
                    <img  alt="legend" src="https://www.vamanaraogaru.com/images/www/image037.jpg"/>
                    <p className="legend">Legend 1</p>
                </div>
                <div>
                    <img  alt="legend" src="https://www.vamanaraogaru.com/images/www/image038.jpg"/>
                    <p className="legend">Legend 1</p>
                </div>
                <div>
                    <img  alt="legend" src="https://www.vamanaraogaru.com/images/www/image039.jpg"/>
                    <p className="legend">Legend 1</p>
                </div>
                <div>
                    <img  alt="legend" src="https://www.vamanaraogaru.com/images/www/image040.jpg"/>
                    <p className="legend">Legend 1</p>
                </div>
                <div>
                    <img  alt="legend" src="https://www.vamanaraogaru.com/images/www/image041.jpg"/>
                    <p className="legend">Legend 1</p>
                </div>
                <div>
                    <img  alt="legend" src="https://www.vamanaraogaru.com/images/www/image042.jpg"/>
                    <p className="legend">Legend 1</p>
                </div>
                <div>
                    <img  alt="legend" src="https://www.vamanaraogaru.com/images/www/image043.jpg"/>
                    <p className="legend">Legend 1</p>
                </div>
                <div>
                    <img  alt="legend" src="https://www.vamanaraogaru.com/images/www/image044.jpg"/>
                    <p className="legend">Legend 1</p>
                </div>
                <div>
                    <img  alt="legend" src="https://www.vamanaraogaru.com/images/www/image045.jpg"/>
                    <p className="legend">Legend 1</p>
                </div>
                <div>
                    <img  alt="legend" src="https://www.vamanaraogaru.com/images/www/image046.jpg"/>
                    <p className="legend">Legend 1</p>
                </div>
                <div>
                    <img  alt="legend" src="https://www.vamanaraogaru.com/images/www/image047.jpg"/>
                    <p className="legend">Legend 1</p>
                </div>
                <div>
                    <img  alt="legend" src="https://www.vamanaraogaru.com/images/www/image048.jpg"/>
                    <p className="legend">Legend 1</p>
                </div>
                <div>
                    <img  alt="legend" src="https://www.vamanaraogaru.com/images/www/image049.jpg"/>
                    <p className="legend">Legend 1</p>
                </div>
                <div>
                    <img  alt="legend" src="https://www.vamanaraogaru.com/images/www/image050.jpg"/>
                    <p className="legend">Legend 1</p>
                </div>
                <div>
                    <img  alt="legend" src="https://www.vamanaraogaru.com/images/www/image051.jpg"/>
                    <p className="legend">Legend 1</p>
                </div>
                <div>
                    <img  alt="legend" src="https://www.vamanaraogaru.com/images/www/image052.jpg"/>
                    <p className="legend">Legend 1</p>
                </div>
                <div>
                    <img  alt="legend" src="https://www.vamanaraogaru.com/images/www/image053.jpg"/>
                    <p className="legend">Legend 1</p>
                </div>
                <div>
                    <img  alt="legend" src="https://www.vamanaraogaru.com/images/www/image054.jpg"/>
                    <p className="legend">Legend 1</p>
                </div>
                <div>
                    <img  alt="legend" src="https://www.vamanaraogaru.com/images/www/image055.jpg"/>
                    <p className="legend">Legend 1</p>
                </div>
                <div>
                    <img  alt="legend" src="https://www.vamanaraogaru.com/images/www/image056.jpg"/>
                    <p className="legend">Legend 1</p>
                </div>
                <div>
                    <img  alt="legend" src="https://www.vamanaraogaru.com/images/www/image057.jpg"/>
                    <p className="legend">Legend 1</p>
                </div>
                <div>
                    <img  alt="legend" src="https://www.vamanaraogaru.com/images/www/image058.jpg"/>
                    <p className="legend">Legend 1</p>
                </div>
                <div>
                    <img  alt="legend" src="https://www.vamanaraogaru.com/images/www/image059.jpg"/>
                    <p className="legend">Legend 1</p>
                </div>
                <div>
                    <img  alt="legend" src="https://www.vamanaraogaru.com/images/www/image060.jpg"/>
                    <p className="legend">Legend 1</p>
                </div>
                <div>
                    <img  alt="legend" src="https://www.vamanaraogaru.com/images/www/image061.jpg"/>
                    <p className="legend">Legend 1</p>
                </div>
                <div>
                    <img  alt="legend" src="https://www.vamanaraogaru.com/images/www/image062.jpg"/>
                    <p className="legend">Legend 1</p>
                </div>
            </Carousel>
        );
    }
}

// ReactDOM.render(<DemoCarousel/>, document.querySelector('.demo-carousel'));

// Don't forget to include the css in your page

// Using webpack or parcel with a style loader
// import styles from 'react-responsive-carousel/lib/styles/carousel.min.css';

// Using html tag:
// <link rel="stylesheet" href="<NODE_MODULES_FOLDER>/react-responsive-carousel/lib/styles/carousel.min.css" />